import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getPatients } from '../../services/api';
import PatientForm from './PatientForm';
import PatientChecklists from './PatientChecklists';

interface Patient {
  _id: string;
  name: string;
  surname: string;
  responsibleDoctor: {
    _id: string;
    name: string;
  };
}

const PatientList: React.FC = () => {
  const [patients, setPatients] = useState<Patient[]>([]);
  const [showForm, setShowForm] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedPatient, setSelectedPatient] = useState<string | null>(null);

  useEffect(() => {
    fetchPatients();
  }, []);

  const fetchPatients = async () => {
    try {
      console.log('Fetching patients...');
      const data = await getPatients();
      console.log('Patients data:', data);
      setPatients(data);
      setError(null);
    } catch (error) {
      console.error('Error fetching patients:', error);
      if (error instanceof Error) {
        setError(`Failed to fetch patients: ${error.message}`);
      } else {
        setError('Failed to fetch patients. Please try again.');
      }
    }
  };

  return (
    <div className="min-h-screen bg-base-200 p-4">
      <div className="flex items-center mb-4">
        <Link to="/dashboard" className="btn btn-ghost btn-circle mr-2">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
          </svg>
        </Link>
        <h2 className="text-2xl font-semibold text-base-content">Patients</h2>
      </div>
      {error && <div className="alert alert-error mb-4">{error}</div>}
      <button
        onClick={() => setShowForm(true)}
        className="btn btn-primary mb-4"
      >
        Add New Patient
      </button>
      {showForm && (
        <PatientForm
          onClose={() => setShowForm(false)}
          onPatientAdded={() => {
            fetchPatients();
            setShowForm(false);
          }}
        />
      )}
      <div className="overflow-x-auto">
        <table className="table w-full">
          <thead>
            <tr>
              <th>Name</th>
              <th>Surname</th>
              <th>Responsible Doctor</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {patients.map((patient) => (
              <tr key={patient._id}>
                <td>{patient.name}</td>
                <td>{patient.surname}</td>
                <td>{patient.responsibleDoctor.name}</td>
                <td>
                  <button
                    onClick={() => setSelectedPatient(patient._id)}
                    className="btn btn-sm btn-info"
                  >
                    View Checklists
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      
      {selectedPatient && (
        <PatientChecklists
          patientId={selectedPatient}
          onClose={() => setSelectedPatient(null)}
        />
      )}
    </div>
  );
};

export default PatientList;