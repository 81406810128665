import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { getTemplate, createTemplate, updateTemplate, deleteTemplate } from '../../services/api';
import { FormElement } from '../../types/template';
import FormBuilder from '../FormBuilder';
import { ChromePicker } from 'react-color';
import { Palette, Trash2, ArrowLeft } from 'lucide-react';

interface TemplateFormData {
  name: string;
  icon: string;
  color: string;
  content: FormElement[];
}

const TemplateForm: React.FC = () => {
  const [formData, setFormData] = useState<TemplateFormData | null>(null);
  const [originalFormData, setOriginalFormData] = useState<TemplateFormData | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const { id } = useParams<{ id?: string }>();
  const history = useHistory();

  useEffect(() => {
    if (id) {
      fetchTemplate();
    } else {
      setFormData({ name: '', icon: '', color: '#ffffff', content: [] });
      setOriginalFormData({ name: '', icon: '', color: '#ffffff', content: [] });
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (formData && originalFormData) {
      setIsDirty(JSON.stringify(formData) !== JSON.stringify(originalFormData));
    }
  }, [formData, originalFormData]);

  const handleContentChange = useCallback((newContent: FormElement[]) => {
    setFormData(prevData => prevData ? { ...prevData, content: newContent } : null);
  }, []);

  const handleOutsideClick = useCallback((event: MouseEvent) => {
    const colorPickers = document.querySelectorAll('.chrome-picker');
    let clickedInsidePicker = false;
    
    colorPickers.forEach(picker => {
      if (picker.contains(event.target as Node)) {
        clickedInsidePicker = true;
      }
    });

    if (!clickedInsidePicker) {
      setShowColorPicker(false);
      // Remove this line:
      // setOpenItemColorPicker(null);
    }
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (isDirty) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty]);

  useEffect(() => {
    if (showColorPicker) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [showColorPicker, handleOutsideClick]);

  const fetchTemplate = async () => {
    if (!id) return;

    try {
      setIsLoading(true);
      const template = await getTemplate(id);
      console.log('Fetched template:', template);
      if (template) {
        const templateData = {
          name: template.name,
          icon: template.icon || '',
          color: template.color || '#ffffff',
          content: template.content || []
        };
        setFormData(templateData);
        setOriginalFormData(templateData);
      } else {
        // Redirect to template list if template is not found
        history.push('/templates');
      }
    } catch (error) {
      console.error('Error fetching template:', error);
      // Redirect to template list on error
      history.push('/templates');
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (formData) {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleColorChange = (color: { hex: string }) => {
    if (formData) {
      setFormData({ ...formData, color: color.hex });
    }
  };

  const handleSave = async () => {
    if (!formData) return;

    setError(null);
    try {
      if (id) {
        await updateTemplate(id, formData);
      } else {
        await createTemplate(formData);
      }
      setIsDirty(false);
      setOriginalFormData(formData);
      history.push('/templates');
    } catch (error) {
      console.error('Error saving template:', error);
      setError('Failed to save template. Please try again.');
    }
  };

  const handleDelete = async () => {
    if (!id) return;

    try {
      await deleteTemplate(id);
      setDeleteConfirmation(false);
      history.push('/templates');
    } catch (error) {
      console.error('Error deleting template:', error);
      setError('Failed to delete template. Please try again.');
    }
  };

  const handleBack = () => {
    if (isDirty) {
      const confirmLeave = window.confirm('You have unsaved changes. Are you sure you want to leave?');
      if (!confirmLeave) {
        return;
      }
    }
    history.push('/templates');
  };

  if (isLoading) {
    return <div className="container mx-auto p-4">Loading...</div>;
  }

  if (!formData) {
    // This condition should not be reached due to the redirect in fetchTemplate
    // but we'll keep it as a fallback
    history.push('/templates');
    return null;
  }

  return (
    <div className="container mx-auto p-4">
      <div className="flex items-center mb-4">
        <button onClick={handleBack} className="mr-4">
          <ArrowLeft size={24} />
        </button>
        <h1 className="text-2xl font-bold">{id ? 'Edit' : 'Create'} Template</h1>
      </div>
      {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">{error}</div>}
      <div className="space-y-4">
        <div className="bg-white shadow rounded-lg p-4 hover:shadow-lg transition-shadow duration-200" style={{ backgroundColor: formData.color }}>
          <div className="mb-4">
            <label htmlFor="name" className="block text-xl font-semibold text-blue-600 mb-2">Template Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              placeholder="Enter template name"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="icon" className="block text-xl font-semibold text-blue-600 mb-2">Template Icon URL:</label>
            <input
              type="url"
              id="icon"
              name="icon"
              value={formData.icon}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              placeholder="Enter icon URL"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="color" className="block text-xl font-semibold text-blue-600 mb-2">Template Color:</label>
            <div className="flex items-center">
              <Palette
                size={24}
                className="cursor-pointer text-gray-600 hover:text-blue-500 mr-2"
                onClick={() => setShowColorPicker(!showColorPicker)}
              />
              <span>{formData.color}</span>
            </div>
            {showColorPicker && (
              <div className="absolute z-10 mt-2">
                <ChromePicker
                  color={formData.color}
                  onChange={handleColorChange}
                  className="chrome-picker"
                />
              </div>
            )}
          </div>
        </div>
        <FormBuilder 
          initialElements={formData.content} 
          onChange={handleContentChange} 
          onOutsideClick={handleOutsideClick}
        />
        <div className="flex justify-between">
          <button 
            onClick={handleSave}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-colors duration-200"
          >
            Save Template
          </button>
          {id && (
            <button 
              onClick={() => setDeleteConfirmation(true)}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition-colors duration-200"
            >
              <Trash2 size={16} className="inline-block mr-2" />
              Delete Template
            </button>
          )}
        </div>
      </div>
      {deleteConfirmation && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
          <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
            <div className="mt-3 text-center">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Delete Template</h3>
              <div className="mt-2 px-7 py-3">
                <p className="text-sm text-gray-500">
                  Are you sure you want to delete this template? This action cannot be undone.
                </p>
              </div>
              <div className="items-center px-4 py-3">
                <button
                  id="ok-btn"
                  className="px-4 py-2 bg-red-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-300"
                  onClick={handleDelete}
                >
                  Delete
                </button>
                <button
                  id="cancel-btn"
                  className="mt-3 px-4 py-2 bg-white text-gray-800 text-base font-medium rounded-md w-full shadow-sm border border-gray-300 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-300"
                  onClick={() => setDeleteConfirmation(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TemplateForm;