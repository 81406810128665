import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001/api';

interface User {
  id: string;
  name: string;
  email: string;
  role: string;
}

export const login = async (email: string, password: string): Promise<User> => {
  console.log('auth service: Logging in');
  console.log('API_URL:', API_URL); // Keep this for debugging
  try {
    const response = await axios.post(`${API_URL}/auth/login`, { email, password });
    const { token, user } = response.data;
    console.log('auth service: Login successful', { token, user });
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
    return user;
  } catch (error) {
    console.error('auth service: Login error', error);
    if (axios.isAxiosError(error) && error.response) {
      console.error('Error response:', error.response.data); // Add this line for debugging
      throw new Error(error.response.data.error || 'An error occurred during login');
    }
    throw new Error('An unexpected error occurred');
  }
};

export const register = async (userData: any) => {
  const response = await axios.post(`${API_URL}/register`, userData);
  return response.data;
};

export const logout = async () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
};

export const refreshToken = async (): Promise<User> => {
  console.log('auth service: Refreshing token');
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('No token found');
    }
    const response = await axios.post(`${API_URL}/refresh-token`, {}, {
      headers: { Authorization: `Bearer ${token}` }
    });
    const { newToken, user } = response.data;
    console.log('auth service: Token refreshed');
    localStorage.setItem('token', newToken);
    return user;
  } catch (error) {
    console.error('auth service: Refresh token error', error);
    throw error;
  }
};

// Create an axios instance with interceptors
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3001/api',
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && error.response.data.shouldRefresh && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const user = await refreshToken();
        const newToken = localStorage.getItem('token'); // Get the new token after refresh
        if (newToken) {
          axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
          originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
        }
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        // If refresh fails, logout the user
        logout();
        window.location.href = '/login';
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);