import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getChecklists } from '../../services/api';
import { ArrowLeft } from 'react-feather';

interface Checklist {
  _id: string;
  name: string;
  patient: {
    name: string;
    surname: string;
  } | null;
  template: {
    name: string;
  } | null;
}

const ChecklistList: React.FC = () => {
  const [checklists, setChecklists] = useState<Checklist[]>([]);

  useEffect(() => {
    fetchChecklists();
  }, []);

  const fetchChecklists = async () => {
    try {
      const data = await getChecklists();
      setChecklists(data);
    } catch (error) {
      console.error('Error fetching checklists:', error);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex items-center mb-4">
        <Link to="/dashboard" className="mr-4">
          <ArrowLeft size={24} />
        </Link>
        <h2 className="text-2xl font-bold">Checklists</h2>
      </div>
      <div className="mt-4">
        <Link
          to="/checklists/new"
          className="btn btn-primary"
        >
          Create New Checklist
        </Link>
      </div>
      <div className="mt-6">
        <table className="table w-full">
          <thead>
            <tr>
              <th>Checklist Name</th>
              <th>Patient Name</th>
              <th>Template</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {checklists.map((checklist) => (
              <tr key={checklist._id}>
                <td>{checklist.name}</td>
                <td>{checklist.patient ? `${checklist.patient.name} ${checklist.patient.surname}` : 'N/A'}</td>
                <td>{checklist.template ? checklist.template.name : 'N/A'}</td>
                <td>
                  <Link to={`/checklists/${checklist._id}`} className="btn btn-sm btn-info">
                    View
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ChecklistList;