import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

interface PrivateRouteProps extends Omit<RouteProps, 'component'> {
  component?: React.ComponentType<any>;
  render?: (props: any) => React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, render, ...rest }) => {
  const isAuthenticated = localStorage.getItem('token') !== null;

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          Component ? <Component {...props} /> : render ? render(props) : null
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;