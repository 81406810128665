import React, { createContext, useState, useEffect } from 'react';
import { login as loginApi, logout as logoutApi, register as registerApi, refreshToken } from '../services/auth';

interface User {
  id: string;
  name: string;
  email: string;
  role: string;
}

interface AuthContextType {
  user: User | null;
  login: (email: string, password: string) => Promise<User>;
  logout: () => Promise<void>;
  register: (userData: any) => Promise<void>;
  refreshUser: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    console.log('AuthProvider: Initial load');
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      try {
        const parsedUser = JSON.parse(storedUser);
        console.log('AuthProvider: Stored user found', parsedUser);
        setUser(parsedUser);
      } catch (error) {
        console.error('Error parsing stored user data:', error);
      }
    }
  }, []);

  const handleLogin = async (email: string, password: string): Promise<User> => {
    console.log('AuthProvider: Logging in');
    const userData = await loginApi(email, password);
    console.log('AuthProvider: Login successful', userData);
    setUser(userData);
    localStorage.setItem('user', JSON.stringify(userData));
    return userData;
  };

  const handleLogout = async () => {
    console.log('AuthProvider: Logging out');
    await logoutApi();
    setUser(null);
    localStorage.removeItem('user');
    localStorage.removeItem('token');
  };

  const handleRegister = async (userData: any) => {
    console.log('AuthProvider: Registering user');
    await registerApi(userData);
  };

  const refreshUser = async () => {
    console.log('AuthProvider: Refreshing user');
    try {
      const userData = await refreshToken();
      console.log('AuthProvider: User refreshed', userData);
      setUser(userData);
      localStorage.setItem('user', JSON.stringify(userData));
    } catch (error) {
      console.error('Error refreshing user data:', error);
      handleLogout();
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login: handleLogin,
        logout: handleLogout,
        register: handleRegister,
        refreshUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};