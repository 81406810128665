import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { getChecklist, updateChecklist, deleteChecklist } from '../../services/api';
import ConfirmationModal from '../common/ConfirmationModal';
import { ArrowLeft } from 'react-feather';
import { Trash2 } from 'lucide-react';

interface ChecklistItem {
  _id: string;
  label: string;
  type: string;
  value: string;
  completed: boolean;
  color: string;
  options?: string[];
}

interface Checklist {
  _id: string;
  name: string;
  patient: {
    name: string;
    surname: string;
  };
  template: {
    name: string;
  };
  items: ChecklistItem[];
}

const ChecklistDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [checklist, setChecklist] = useState<Checklist | null>(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const history = useHistory();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchChecklist();
  }, [id]);

  const fetchChecklist = async () => {
    try {
      setIsLoading(true);
      const data = await getChecklist(id);
      if (data) {
        setChecklist(data);
      } else {
        // Checklist not found, redirect to dashboard
        history.push('/dashboard');
      }
    } catch (error) {
      console.error('Error fetching checklist:', error);
      // Redirect to dashboard on error
      history.push('/dashboard');
    } finally {
      setIsLoading(false);
    }
  };

  const handleItemChange = (itemId: string, completed: boolean) => {
    if (checklist) {
      const updatedItems = checklist.items.map(item =>
        item._id === itemId ? { ...item, completed } : item
      );
      setChecklist({ ...checklist, items: updatedItems });
      setHasUnsavedChanges(true);
    }
  };

  const handleValueChange = (itemId: string, value: string) => {
    if (checklist) {
      const updatedItems = checklist.items.map(item =>
        item._id === itemId ? { ...item, value } : item
      );
      setChecklist({ ...checklist, items: updatedItems });
      setHasUnsavedChanges(true);
    }
  };

  const handleSave = async () => {
    if (checklist) {
      try {
        await updateChecklist(checklist._id, { items: checklist.items });
        history.push('/checklists');
      } catch (error) {
        console.error('Error updating checklist:', error);
      }
    }
  };

  const handleClear = () => {
    if (checklist) {
      const clearedItems = checklist.items.map(item => ({ ...item, completed: false, value: '' }));
      setChecklist({ ...checklist, items: clearedItems });
    }
  };

  const handleDeleteClick = () => {
    setShowDeleteConfirmation(true);
  };

  const handleDeleteConfirm = async () => {
    if (checklist) {
      try {
        await deleteChecklist(checklist._id);
        setShowDeleteConfirmation(false);
        history.push('/checklists');
      } catch (error) {
        console.error('Error deleting checklist:', error);
      }
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteConfirmation(false);
  };

  const handleBack = () => {
    if (hasUnsavedChanges) {
      if (window.confirm('You have unsaved changes. Are you sure you want to leave?')) {
        history.push('/checklists');
      }
    } else {
      history.push('/checklists');
    }
  };

  const renderItemInput = (item: ChecklistItem) => {
    switch (item.type) {
      case 'short text':
        return (
          <input
            type="text"
            value={item.value}
            onChange={(e) => handleValueChange(item._id, e.target.value)}
            className="w-full p-2 border rounded"
            placeholder="Enter short text"
          />
        );
      case 'long text':
        return (
          <textarea
            value={item.value}
            onChange={(e) => handleValueChange(item._id, e.target.value)}
            className="w-full p-2 border rounded"
            rows={4}
            placeholder="Enter long text"
          />
        );
      case 'number':
        return (
          <input
            type="number"
            value={item.value}
            onChange={(e) => handleValueChange(item._id, e.target.value)}
            className="w-full p-2 border rounded"
            placeholder="Enter a number"
          />
        );
      case 'date':
        return (
          <input
            type="date"
            value={item.value}
            onChange={(e) => handleValueChange(item._id, e.target.value)}
            className="w-full p-2 border rounded"
          />
        );
      case 'checkbox':
        return (
          <div>
            {item.options && item.options.map((option, index) => (
              <label key={index} className="block">
                <input
                  type="checkbox"
                  value={option}
                  checked={item.value.split(',').includes(option)}
                  onChange={(e) => {
                    const currentValues = item.value ? item.value.split(',') : [];
                    const updatedValues = e.target.checked
                      ? [...currentValues, option]
                      : currentValues.filter(val => val !== option);
                    handleValueChange(item._id, updatedValues.join(','));
                  }}
                  className="mr-2"
                />
                {option}
              </label>
            ))}
          </div>
        );
      case 'dropdown':
        return (
          <select
            value={item.value}
            onChange={(e) => handleValueChange(item._id, e.target.value)}
            className="w-full p-2 border rounded"
          >
            <option value="">Select an option</option>
            {item.options && item.options.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        );
      case 'radio':
        return (
          <div>
            {item.options && item.options.map((option, index) => (
              <label key={index} className="block">
                <input
                  type="radio"
                  value={option}
                  checked={item.value === option}
                  onChange={(e) => handleValueChange(item._id, e.target.value)}
                  className="mr-2"
                />
                {option}
              </label>
            ))}
          </div>
        );
      default:
        return null;
    }
  };

  if (isLoading) {
    return <div className="container mx-auto p-4">Loading...</div>;
  }

  if (!checklist) {
    return null; // This will prevent any rendering while redirecting
  }

  return (
    <div className="container mx-auto p-4">
      <div className="flex items-center mb-4">
        <button onClick={handleBack} className="mr-4">
          <ArrowLeft size={24} />
        </button>
        <h2 className="text-2xl font-bold">{checklist.name}</h2>
      </div>
      <p>Patient: {`${checklist.patient.name} ${checklist.patient.surname}`}</p>
      <div className="mt-4">
        {checklist.items.map(item => (
          <div key={item._id} className="mb-4 p-4 border rounded" style={{ backgroundColor: item.color }}>
            <div className="flex items-center mb-2">
              <input
                type="checkbox"
                checked={item.completed}
                onChange={(e) => handleItemChange(item._id, e.target.checked)}
                className="mr-2"
              />
              <span className="font-semibold">{item.label}</span>
            </div>
            <div className="mt-2">
              {renderItemInput(item)}
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-between mt-4">
        <div>
          <button onClick={handleSave} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-colors duration-200 mr-2">Save</button>
          <button onClick={handleClear} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded transition-colors duration-200">Clear All</button>
        </div>
        <button 
          onClick={handleDeleteClick}
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition-colors duration-200"
        >
          <Trash2 size={16} className="inline-block mr-2" />
          Delete Checklist
        </button>
      </div>
      <ConfirmationModal
        isOpen={showDeleteConfirmation}
        onConfirm={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
        title="Delete Checklist"
        message="Are you sure you want to delete this checklist? This action cannot be undone."
      />
    </div>
  );
};

export default ChecklistDetail;