import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getChecklistsByPatient } from '../../services/api';

interface Checklist {
  _id: string;
  name: string;
  template: {
    name: string;
  };
}

interface PatientChecklistsProps {
  patientId: string;
  onClose: () => void;
}

const PatientChecklists: React.FC<PatientChecklistsProps> = ({ patientId, onClose }) => {
  const [checklists, setChecklists] = useState<Checklist[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchChecklists = async () => {
      try {
        setIsLoading(true);
        const data = await getChecklistsByPatient(patientId);
        setChecklists(data);
        setError(null);
      } catch (error) {
        console.error('Error fetching checklists:', error);
        setError('Failed to fetch checklists. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchChecklists();
  }, [patientId]);

  if (isLoading) {
    return <div className="text-center">Loading checklists...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500">{error}</div>;
  }

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
      <div className="bg-white p-5 rounded-lg w-full max-w-md">
        <h2 className="text-xl font-bold mb-4">Patient Checklists</h2>
        {checklists.length === 0 ? (
          <p>No checklists found for this patient.</p>
        ) : (
          <ul className="space-y-2">
            {checklists.map((checklist) => (
              <li key={checklist._id} className="border p-2 rounded">
                <Link to={`/checklists/${checklist._id}`} className="text-blue-500 hover:underline">
                  {checklist.name} ({checklist.template.name})
                </Link>
              </li>
            ))}
          </ul>
        )}
        <button
          onClick={onClose}
          className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default PatientChecklists;
