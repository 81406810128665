import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import Dashboard from './components/Dashboard/Dashboard';
import TemplateList from './components/Templates/TemplateList';
import TemplateForm from './components/Templates/TemplateForm';
import ChecklistList from './components/Checklists/ChecklistList';
import PatientList from './components/Patients/PatientList';
import UserPanel from './components/UserPanel/UserPanel';
import RoleManager from './components/RoleManager/RoleManager';
import PrivateRoute from './components/Auth/PrivateRoute';
import ChecklistForm from './components/Checklists/ChecklistForm';
import ChecklistDetail from './components/Checklists/ChecklistDetail';

const App: React.FC = () => {
  return (
    <AuthProvider>
      <Router>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/templates" component={TemplateList} />
          <PrivateRoute exact path="/templates/new" component={TemplateForm} />
          <PrivateRoute exact path="/templates/:id" component={TemplateForm} />
          <PrivateRoute exact path="/checklists" component={ChecklistList} />
          <PrivateRoute exact path="/checklists/new" component={ChecklistForm} />
          <PrivateRoute exact path="/checklists/:id" component={ChecklistDetail} />
          <PrivateRoute exact path="/patients" component={PatientList} />
          <PrivateRoute exact path="/role-manager" component={RoleManager} />
          <PrivateRoute exact path="/user-panel" component={UserPanel} />
          <PrivateRoute path="/" component={Dashboard} />
        </Switch>
      </Router>
    </AuthProvider>
  );
};

export default App;