import React, { useState, useEffect } from 'react';
import { createPatient, getUsers } from '../../services/api';

interface User {
  _id: string;
  name: string;
}

interface PatientFormProps {
  onClose: () => void;
  onPatientAdded: () => void;
}

const PatientForm: React.FC<PatientFormProps> = ({ onClose, onPatientAdded }) => {
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [responsibleDoctor, setResponsibleDoctor] = useState('');
  const [users, setUsers] = useState<User[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const data = await getUsers();
      setUsers(data);
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('Failed to fetch users. Please try again.');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      console.log('Submitting patient data:', { name, surname, responsibleDoctor });
      const newPatient = await createPatient({ name, surname, responsibleDoctor });
      console.log('Patient created successfully:', newPatient);
      onPatientAdded();
      onClose();
    } catch (error) {
      console.error('Error creating patient:', error);
      setError('Failed to create patient. Please try again.');
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">Add New Patient</h3>
        {error && <div className="text-red-500 mb-4">{error}</div>}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="surname" className="block text-sm font-medium text-gray-700">Surname</label>
            <input
              type="text"
              id="surname"
              value={surname}
              onChange={(e) => setSurname(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="responsibleDoctor" className="block text-sm font-medium text-gray-700">Responsible Doctor</label>
            <select
              id="responsibleDoctor"
              value={responsibleDoctor}
              onChange={(e) => setResponsibleDoctor(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              required
            >
              <option value="">Select a doctor</option>
              {users.map((user) => (
                <option key={user._id} value={user._id}>{user.name}</option>
              ))}
            </select>
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="mr-2 bg-gray-200 hover:bg-gray-300 text-black font-bold py-2 px-4 rounded"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Add Patient
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PatientForm;
