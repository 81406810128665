import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getTemplates, getPatients, createChecklist } from '../../services/api';

interface Template {
  _id: string;
  name: string;
}

interface Patient {
  _id: string;
  name: string;
  surname: string;
}

const ChecklistForm: React.FC = () => {
  const [templates, setTemplates] = useState<Template[]>([]);
  const [patients, setPatients] = useState<Patient[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [selectedPatient, setSelectedPatient] = useState('');
  const [checklistName, setChecklistName] = useState('');
  const history = useHistory();

  useEffect(() => {
    fetchTemplatesAndPatients();
  }, []);

  const fetchTemplatesAndPatients = async () => {
    try {
      const [templatesData, patientsData] = await Promise.all([
        getTemplates(),
        getPatients()
      ]);
      setTemplates(templatesData);
      setPatients(patientsData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await createChecklist({
        name: checklistName,
        patient: selectedPatient,
        template: selectedTemplate
      });
      history.push('/checklists');
    } catch (error) {
      console.error('Error creating checklist:', error);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Create New Checklist</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="checklistName" className="block mb-2">Checklist Name</label>
          <input
            type="text"
            id="checklistName"
            value={checklistName}
            onChange={(e) => setChecklistName(e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="template" className="block mb-2">Template</label>
          <select
            id="template"
            value={selectedTemplate}
            onChange={(e) => setSelectedTemplate(e.target.value)}
            className="w-full p-2 border rounded"
            required
          >
            <option value="">Select a template</option>
            {templates.map((template) => (
              <option key={template._id} value={template._id}>{template.name}</option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label htmlFor="patient" className="block mb-2">Patient</label>
          <select
            id="patient"
            value={selectedPatient}
            onChange={(e) => setSelectedPatient(e.target.value)}
            className="w-full p-2 border rounded"
            required
          >
            <option value="">Select a patient</option>
            {patients.map((patient) => (
              <option key={patient._id} value={patient._id}>{`${patient.name} ${patient.surname}`}</option>
            ))}
          </select>
        </div>
        <button type="submit" className="btn btn-primary">Create Checklist</button>
      </form>
    </div>
  );
};

export default ChecklistForm;
