import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { deleteRole, createRole, updateRole, getRoles } from '../../services/api';
import axios from 'axios';
import { ArrowLeft, Trash2 } from 'lucide-react';

export interface Role {
  _id: string;
  name: string;
  permissions: string[];
  __v: number;
}

export interface RoleManagerProps {
  roles: Role[];
  onRolesChange: () => void;
}

const RoleManager: React.FC<RoleManagerProps> = ({ roles: initialRoles = [], onRolesChange }) => {
  const history = useHistory();
  const [roles, setRoles] = useState<Role[]>(initialRoles);
  const [newRole, setNewRole] = useState<Omit<Role, '_id'>>({
    name: '',
    permissions: [],
    __v: 0
  });
  const [editingRole, setEditingRole] = useState<Role | null>(null);
  const [error, setError] = useState<string>('');
  const [deleteConfirmation, setDeleteConfirmation] = useState<string | null>(null);

  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = async () => {
    try {
      const fetchedRoles = await getRoles();
      console.log('Fetched roles:', fetchedRoles);
      setRoles(fetchedRoles);
    } catch (error) {
      console.error('Error fetching roles:', error);
      setError('Failed to fetch roles');
    }
  };

  // Update this PERMISSIONS array to include all allowed permissions
  const PERMISSIONS = [
    'Create roles', 'Edit roles', 'Delete roles', 'View roles',
    'Create users', 'Edit users', 'Delete users', 'View users',
    'Create checklist template', 'Edit checklist template', 'Delete checklist template', 'View checklist template',
    'Create checklist', 'Edit checklist', 'Delete checklist', 'View checklist',
    'Create patient', 'Edit patient', 'Delete patient', 'View patient',
  ];

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (editingRole) {
      setEditingRole({ ...editingRole, [name]: value });
    } else {
      setNewRole({ ...newRole, [name]: value });
    }
  };

  const handlePermissionChange = (permission: string) => {
    const roleToUpdate = editingRole || newRole;
    const updatedPermissions = roleToUpdate.permissions.includes(permission)
      ? roleToUpdate.permissions.filter(p => p !== permission)
      : [...roleToUpdate.permissions, permission];

    if (editingRole) {
      setEditingRole({ ...editingRole, permissions: updatedPermissions });
    } else {
      setNewRole({ ...newRole, permissions: updatedPermissions });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    const roleToSubmit = editingRole || newRole;
    console.log('Submitting role:', roleToSubmit);

    try {
      if (editingRole) {
        await updateRole(editingRole._id, editingRole);
      } else {
        await createRole(newRole);
      }
      setNewRole({ name: '', permissions: [], __v: 0 });
      setEditingRole(null);
      fetchRoles(); // Add this line to refresh the roles list
      if (onRolesChange) {
        onRolesChange(); // Call onRolesChange after successful creation/update
      }
    } catch (error) {
      console.error(`Error ${editingRole ? 'updating' : 'creating'} role:`, error);
      if (axios.isAxiosError(error) && error.response) {
        setError(`Error ${editingRole ? 'updating' : 'creating'} role: ${error.response.data.error || error.response.data.details || 'Unknown error'}`);
      } else if (error instanceof Error) {
        setError(`Error ${editingRole ? 'updating' : 'creating'} role: ${error.message}`);
      } else {
        setError(`An unknown error occurred while ${editingRole ? 'updating' : 'creating'} the role`);
      }
    }
  };

  const handleEdit = (role: Role) => {
    setEditingRole(role);
  };

  const confirmDeleteRole = (roleId: string) => {
    setDeleteConfirmation(roleId);
  };

  const handleDelete = async (roleId: string) => {
    try {
      await deleteRole(roleId);
      console.log('Role deleted successfully');
      setDeleteConfirmation(null);
      fetchRoles();
      if (onRolesChange) {
        onRolesChange();
      }
    } catch (error) {
      console.error('Error deleting role:', error);
      if (axios.isAxiosError(error) && error.response) {
        setError(`Error deleting role: ${error.response.data.error || 'An unknown error occurred'}`);
      } else if (error instanceof Error) {
        setError(`Error deleting role: ${error.message}`);
      } else {
        setError('An unknown error occurred while deleting the role');
      }
    }
  };

  const handleBack = () => {
    history.push('/dashboard');
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex items-center mb-4">
        <button onClick={handleBack} className="mr-4">
          <ArrowLeft size={24} />
        </button>
        <h2 className="text-2xl font-bold">Role Manager</h2>
      </div>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      <form onSubmit={handleSubmit} className="mb-8">
        <input
          type="text"
          name="name"
          value={editingRole?.name || newRole.name}
          onChange={handleInputChange}
          placeholder="Role Name"
          className="input input-bordered mr-2"
          required
        />
        <div className="mt-4">
          <h3 className="text-lg font-semibold mb-2">Permissions:</h3>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
            {PERMISSIONS.map(permission => (
              <label key={permission} className="flex items-center mb-2">
                <input
                  type="checkbox"
                  checked={(editingRole || newRole).permissions.includes(permission)}
                  onChange={() => handlePermissionChange(permission)}
                  className="checkbox mr-2"
                />
                <span className="text-sm">{permission}</span>
              </label>
            ))}
          </div>
        </div>
        <button type="submit" className="btn btn-primary mt-4">
          {editingRole ? 'Update Role' : 'Add Role'}
        </button>
        {editingRole && (
          <button type="button" className="btn btn-secondary mt-4 ml-2" onClick={() => setEditingRole(null)}>
            Cancel Edit
          </button>
        )}
      </form>
      <div className="overflow-x-auto">
        <table className="table w-full">
          <thead>
            <tr>
              <th>Role Name</th>
              <th>Permissions</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {roles.length === 0 ? (
              <tr>
                <td colSpan={3}>No roles found</td>
              </tr>
            ) : (
              roles.map((role) => (
                <tr key={role._id}>
                  <td>{role.name}</td>
                  <td>{role.permissions.join(', ')}</td>
                  <td>
                    <button className="btn btn-sm btn-info mr-2" onClick={() => handleEdit(role)}>
                      Edit
                    </button>
                    <button 
                      onClick={() => confirmDeleteRole(role._id)}
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition-colors duration-200"
                    >
                      <Trash2 size={16} className="inline-block mr-2" />
                      Delete
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      {deleteConfirmation && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
          <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
            <div className="mt-3 text-center">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Delete Role</h3>
              <div className="mt-2 px-7 py-3">
                <p className="text-sm text-gray-500">
                  Are you sure you want to delete this role? This action cannot be undone.
                </p>
              </div>
              <div className="items-center px-4 py-3">
                <button
                  id="ok-btn"
                  className="px-4 py-2 bg-red-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-300"
                  onClick={() => handleDelete(deleteConfirmation)}
                >
                  Delete
                </button>
                <button
                  id="cancel-btn"
                  className="mt-3 px-4 py-2 bg-white text-gray-800 text-base font-medium rounded-md w-full shadow-sm border border-gray-300 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-300"
                  onClick={() => setDeleteConfirmation(null)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RoleManager;