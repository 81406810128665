import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { createUser, updateUser, deleteUser, getUsers, getRoles } from '../../services/api';
import axios from 'axios';
import { ArrowLeft, Trash2 } from 'lucide-react';

interface User {
  _id: string;
  name: string;
  email: string;
  role: string;
  identificationNumber: string;
  surname: string;
  password?: string;
  pictureUrl: string;
}

interface Role {
  _id: string;
  name: string;
}

interface UserPanelProps {
  users: User[];
  roles: Role[];
  onUsersChange: () => void;
}

const UserPanel: React.FC<UserPanelProps> = ({ users: initialUsers = [], roles: initialRoles = [], onUsersChange }) => {
  const history = useHistory();
  const [localUsers, setLocalUsers] = useState<User[]>(initialUsers);
  const [roles, setRoles] = useState<Role[]>(initialRoles);
  const [newUser, setNewUser] = useState<Omit<User, '_id'>>({
    name: '',
    email: '',
    role: '',
    identificationNumber: '',
    surname: '',
    password: '',
    pictureUrl: '',
  });
  const [editingUser, setEditingUser] = useState<User | null>(null);
  const [error, setError] = useState<string>('');
  const [deleteConfirmation, setDeleteConfirmation] = useState<string | null>(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const [fetchedUsers, fetchedRoles] = await Promise.all([getUsers(), getRoles()]);
      setLocalUsers(fetchedUsers);
      setRoles(fetchedRoles);
    } catch (error) {
      console.error('Error fetching data:', error);
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        // Redirect to login page if the error is due to unauthorized access
        history.push('/login');
      } else {
        setError('Failed to fetch users or roles');
      }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    if (editingUser) {
      setEditingUser({ ...editingUser, [name]: value });
    } else {
      setNewUser({ ...newUser, [name]: value });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    try {
      if (editingUser) {
        const userDataToUpdate = { ...editingUser };
        if (!userDataToUpdate.password) {
          delete userDataToUpdate.password;  // Don't send password if it's not changed
        }
        await updateUser(editingUser._id, userDataToUpdate);
      } else {
        await createUser(newUser);
      }
      setNewUser({ name: '', email: '', role: '', identificationNumber: '', surname: '', password: '', pictureUrl: '' });
      setEditingUser(null);
      fetchData(); // Add this line to refresh the users list
      if (onUsersChange) {
        onUsersChange(); // Call onUsersChange if it exists
      }
    } catch (error) {
      console.error(`Error ${editingUser ? 'updating' : 'creating'} user:`, error);
      if (axios.isAxiosError(error) && error.response) {
        setError(`Error ${editingUser ? 'updating' : 'creating'} user: ${error.response.data.error || error.response.data.message || 'Unknown error'}`);
      } else if (error instanceof Error) {
        setError(`Error ${editingUser ? 'updating' : 'creating'} user: ${error.message}`);
      } else {
        setError(`An unknown error occurred while ${editingUser ? 'updating' : 'creating'} the user`);
      }
    }
  };

  const handleEdit = (user: User) => {
    setEditingUser({ ...user, password: '' }); // Clear password when editing
  };

  const confirmDeleteUser = (userId: string) => {
    setDeleteConfirmation(userId);
  };

  const handleDelete = async (userId: string) => {
    try {
      await deleteUser(userId);
      setDeleteConfirmation(null);
      fetchData(); // Fetch data again after deletion
      if (onUsersChange) {
        onUsersChange(); // Call onUsersChange if it exists
      }
    } catch (error) {
      console.error('Error deleting user:', error);
      if (axios.isAxiosError(error) && error.response) {
        setError(`Error deleting user: ${error.response.data.error || 'An unknown error occurred'}`);
      } else if (error instanceof Error) {
        setError(`Error deleting user: ${error.message}`);
      } else {
        setError('An unknown error occurred while deleting the user');
      }
    }
  };

  const handleBack = () => {
    history.push('/dashboard');
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex items-center mb-4">
        <button onClick={handleBack} className="mr-4">
          <ArrowLeft size={24} />
        </button>
        <h2 className="text-2xl font-bold">User Panel</h2>
      </div>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      <form onSubmit={handleSubmit} className="mb-8">
        <input
          type="text"
          name="name"
          value={editingUser?.name || newUser.name}
          onChange={handleInputChange}
          placeholder="First Name"
          className="input input-bordered mr-2 mb-2"
          required
        />
        <input
          type="text"
          name="surname"
          value={editingUser?.surname || newUser.surname}
          onChange={handleInputChange}
          placeholder="Surname"
          className="input input-bordered mr-2 mb-2"
          required
        />
        <input
          type="email"
          name="email"
          value={editingUser?.email || newUser.email}
          onChange={handleInputChange}
          placeholder="Email"
          className="input input-bordered mr-2 mb-2"
          required
        />
        <input
          type="password"
          name="password"
          value={editingUser?.password || newUser.password}
          onChange={handleInputChange}
          placeholder={editingUser ? "New Password (leave blank to keep current)" : "Password"}
          className="input input-bordered mr-2 mb-2"
          required={!editingUser}
        />
        <input
          type="text"
          name="identificationNumber"
          value={editingUser?.identificationNumber || newUser.identificationNumber}
          onChange={handleInputChange}
          placeholder="Identification Number"
          className="input input-bordered mr-2 mb-2"
          required
        />
        <input
          type="text"
          name="pictureUrl"
          value={editingUser?.pictureUrl || newUser.pictureUrl}
          onChange={handleInputChange}
          placeholder="Picture URL"
          className="input input-bordered mr-2 mb-2"
        />
        <select
          name="role"
          value={editingUser?.role || newUser.role}
          onChange={handleInputChange}
          className="select select-bordered mb-2"
          required
        >
          <option value="">Select Role</option>
          {roles.map((role) => (
            <option key={role._id} value={role.name}>
              {role.name}
            </option>
          ))}
        </select>
        <button type="submit" className="btn btn-primary mt-4">
          {editingUser ? 'Update User' : 'Add User'}
        </button>
        {editingUser && (
          <button type="button" className="btn btn-secondary mt-4 ml-2" onClick={() => setEditingUser(null)}>
            Cancel Edit
          </button>
        )}
      </form>
      <div className="overflow-x-auto">
        <table className="table w-full">
          <thead>
            <tr>
              <th>First Name</th>
              <th>Surname</th>
              <th>Email</th>
              <th>Identification Number</th>
              <th>Picture</th>
              <th>Role</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {localUsers.map((user) => (
              <tr key={user._id}>
                <td>{user.name}</td>
                <td>{user.surname}</td>
                <td>{user.email}</td>
                <td>{user.identificationNumber}</td>
                <td>
                  {user.pictureUrl && (
                    <img src={user.pictureUrl} alt={`${user.name}'s picture`} className="w-10 h-10 rounded-full" />
                  )}
                </td>
                <td>{user.role}</td>
                <td>
                  <button className="btn btn-sm btn-info mr-2" onClick={() => handleEdit(user)}>
                    Edit
                  </button>
                  <button 
                    onClick={() => setDeleteConfirmation(user._id)}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition-colors duration-200"
                  >
                    <Trash2 size={16} className="inline-block mr-2" />
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {deleteConfirmation && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
          <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
            <div className="mt-3 text-center">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Delete User</h3>
              <div className="mt-2 px-7 py-3">
                <p className="text-sm text-gray-500">
                  Are you sure you want to delete this user? This action cannot be undone.
                </p>
              </div>
              <div className="items-center px-4 py-3">
                <button
                  id="ok-btn"
                  className="px-4 py-2 bg-red-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-300"
                  onClick={() => handleDelete(deleteConfirmation)}
                >
                  Delete
                </button>
                <button
                  id="cancel-btn"
                  className="mt-3 px-4 py-2 bg-white text-gray-800 text-base font-medium rounded-md w-full shadow-sm border border-gray-300 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-300"
                  onClick={() => setDeleteConfirmation(null)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserPanel;