import React, { useState, useEffect, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { getUserPermissions } from '../../services/api';

interface DashboardCardProps {
  title: string;
  permissions: string[];
  userPermissions: string[];
  buttons: { label: string; path: string; requiredPermission: string }[];
}

const DashboardCard: React.FC<DashboardCardProps> = ({ title, permissions, userPermissions, buttons }) => {
  const hasAnyPermission = permissions.some(permission => userPermissions.includes(permission));

  if (!hasAnyPermission) return null;

  return (
    <div className="bg-white shadow-md rounded-lg p-6 m-4">
      <h2 className="text-xl font-bold mb-4">{title}</h2>
      <div className="flex flex-wrap">
        {buttons.map(button => (
          userPermissions.includes(button.requiredPermission) && (
            <Link
              key={button.label}
              to={button.path}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2 mb-2"
            >
              {button.label}
            </Link>
          )
        ))}
      </div>
    </div>
  );
};

const Dashboard: React.FC = () => {
  const [userPermissions, setUserPermissions] = useState<string[]>([]);
  const [userName, setUserName] = useState<string>('');
  const history = useHistory();

  const fetchData = useCallback(async () => {
    try {
      const storedUser = localStorage.getItem('user');
      if (storedUser) {
        const user = JSON.parse(storedUser);
        setUserName(user.name);
        const permissions = await getUserPermissions(user.id);
        console.log('Fetched permissions:', permissions);
        setUserPermissions(permissions);
      } else {
        // If there's no user data in localStorage, redirect to login
        history.push('/login');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      // Redirect to login page on error
      history.push('/login');
    }
  }, [history]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    history.push('/login');
  };

  const dashboardCards = [
    {
      title: 'Roles',
      permissions: ['Create roles', 'Edit roles', 'Delete roles', 'View roles'],
      buttons: [
        { label: 'Manage Roles', path: '/role-manager', requiredPermission: 'View roles' },
      ],
    },
    {
      title: 'Users',
      permissions: ['Create users', 'Edit users', 'Delete users', 'View users'],
      buttons: [
        { label: 'Manage Users', path: '/user-panel', requiredPermission: 'View users' },
      ],
    },
    {
      title: 'Templates',
      permissions: ['Create checklist template', 'Edit checklist template', 'Delete checklist template', 'View checklist template'],
      buttons: [
        { label: 'Manage Templates', path: '/templates', requiredPermission: 'View checklist template' },
      ],
    },
    {
      title: 'Checklists',
      permissions: ['Create checklist', 'Edit checklist', 'Delete checklist', 'View checklist'],
      buttons: [
        { label: 'Manage Checklists', path: '/checklists', requiredPermission: 'View checklist' },
      ],
    },
    {
      title: 'Patients',
      permissions: ['Create patient', 'Edit patient', 'Delete patient', 'View patient'],
      buttons: [
        { label: 'Manage Patients', path: '/patients', requiredPermission: 'View patient' },
      ],
    },
  ];

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Welcome, {userName}</h1>
        <button 
          onClick={handleLogout}
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
        >
          Logout
        </button>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {dashboardCards.map(card => (
          <DashboardCard
            key={card.title}
            title={card.title}
            permissions={card.permissions}
            userPermissions={userPermissions}
            buttons={card.buttons}
          />
        ))}
      </div>
    </div>
  );
};

export default Dashboard;