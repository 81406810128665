import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { getTemplates } from '../../services/api';
import { ArrowLeft } from 'lucide-react';

interface Template {
  _id: string;
  name: string;
  icon: string;
  color: string;
}

const TemplateList: React.FC = () => {
  const [templates, setTemplates] = useState<Template[]>([]);
  const history = useHistory();

  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    try {
      const fetchedTemplates = await getTemplates();
      setTemplates(fetchedTemplates);
    } catch (error) {
      console.error('Error fetching templates:', error);
    }
  };

  const handleBack = () => {
    history.push('/dashboard');
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex items-center mb-4">
        <button onClick={handleBack} className="mr-4">
          <ArrowLeft size={24} />
        </button>
        <h1 className="text-2xl font-bold">Manage Templates</h1>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {templates.map((template) => (
          <Link
            key={template._id}
            to={`/templates/${template._id}`}
            className="shadow rounded-lg p-4 hover:shadow-lg transition-shadow duration-200 flex items-center"
            style={{ backgroundColor: template.color }}
          >
            {template.icon && (
              <img src={template.icon} alt={template.name} className="w-12 h-12 mr-4 object-cover rounded" />
            )}
            <div>
              <h2 className="text-xl font-semibold text-blue-600">{template.name}</h2>
              <p className="text-gray-600 mt-2">Click to edit</p>
            </div>
          </Link>
        ))}
      </div>
      <Link
        to="/templates/new"
        className="mt-6 inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-colors duration-200"
      >
        Create New Template
      </Link>
    </div>
  );
};

export default TemplateList;