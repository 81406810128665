import axios from 'axios';
import { FormElement } from '../types/template';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001/api';

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const handleApiError = (error: any) => {
  console.error('API Error:', error);
  if (axios.isAxiosError(error) && error.response) {
    console.error('Error response:', error.response.data);
    throw new Error(error.response.data.error || error.response.data.message || 'An error occurred');
  }
  throw error;
};

// Roles
export const getRoles = async () => {
  try {
    const response = await axiosInstance.get('/roles');
    console.log('Fetched roles:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching roles:', error);
    return handleApiError(error);
  }
};

export const createRole = async (roleData: any) => {
  try {
    const response = await axiosInstance.post('/roles', roleData);
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const updateRole = async (id: string, roleData: any) => {
  try {
    const response = await axiosInstance.put(`/roles/${id}`, roleData);
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const deleteRole = async (id: string) => {
  try {
    const response = await axiosInstance.delete(`/roles/${id}`);
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

// Users
export const getUsers = async () => {
  const response = await axiosInstance.get('/users');
  return response.data;
};

export const createUser = async (userData: any) => {
  const response = await axiosInstance.post('/users', userData);
  return response.data;
};

export const updateUser = async (id: string, userData: any) => {
  const response = await axiosInstance.put(`/users/${id}`, userData);
  return response.data;
};

export const deleteUser = async (id: string) => {
  try {
    const response = await axiosInstance.delete(`/users/${id}`);
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const getUserPermissions = async (userId: string) => {
  try {
    const response = await axiosInstance.get(`/users/${userId}/permissions`);
    return response.data;
  } catch (error) {
    console.error('Error fetching user permissions:', error);
    throw error;
  }
};

// Templates
export const getTemplates = async () => {
  try {
    const response = await axiosInstance.get('/templates');
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const getTemplate = async (id: string | undefined) => {
  if (!id) {
    console.error('No template ID provided');
    throw new Error('Template ID is required');
  }

  try {
    console.log(`Fetching template with id: ${id}`);
    const response = await axiosInstance.get(`/templates/${id}`);
    console.log('Template fetch response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching template:', error);
    return handleApiError(error);
  }
};

export const createTemplate = async (templateData: { name: string, icon: string, content: FormElement[] }) => {
  try {
    const response = await axiosInstance.post('/templates', templateData);
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const updateTemplate = async (id: string, templateData: { name: string, icon: string, content: FormElement[] }) => {
  try {
    const response = await axiosInstance.put(`/templates/${id}`, templateData);
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const deleteTemplate = async (id: string) => {
  try {
    const response = await axiosInstance.delete(`/templates/${id}`);
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

// Checklists
export const getChecklists = async () => {
  const response = await axiosInstance.get(`${API_URL}/checklists`);
  return response.data;
};

export const createChecklist = async (checklistData: { name: string; patient: string; template: string }) => {
  const response = await axiosInstance.post('/checklists', checklistData);
  return response.data;
};

export const getChecklist = async (id: string) => {
  const response = await axiosInstance.get(`/checklists/${id}`);
  return response.data;
};

export const updateChecklist = async (id: string, updateData: { items: any[] }) => {
  const response = await axiosInstance.put(`/checklists/${id}`, updateData);
  return response.data;
};

export const deleteChecklist = async (id: string) => {
  const response = await axiosInstance.delete(`/checklists/${id}`);
  return response.data;
};

// Patients
export const getPatients = async () => {
  try {
    const response = await axiosInstance.get('/patients');
    return response.data;
  } catch (error) {
    console.error('Error fetching patients:', error);
    throw error;
  }
};

export const createPatient = async (patientData: { name: string; surname: string; responsibleDoctor: string }) => {
  try {
    const response = await axiosInstance.post('/patients', patientData);
    return response.data;
  } catch (error) {
    console.error('Error creating patient:', error);
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(error.response.data.message || 'Failed to create patient');
    }
    throw error;
  }
};

// Checklists by Patient
export const getChecklistsByPatient = async (patientId: string) => {
  try {
    const response = await axiosInstance.get(`/checklists/patient/${patientId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching checklists by patient:', error);
    throw error;
  }
};