import React, { useState, useEffect, useRef, useCallback } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { Plus, X, GripVertical, Palette } from 'lucide-react';
import { ChromePicker } from 'react-color';
import { FormElement } from '../types/template';

interface FormBuilderProps {
  initialElements: FormElement[];
  onChange: (elements: FormElement[]) => void;
  onOutsideClick: (event: MouseEvent) => void;
}

const FormBuilder: React.FC<FormBuilderProps> = ({ initialElements, onChange, onOutsideClick }) => {
  const [formElements, setFormElements] = useState<FormElement[]>(initialElements);
  const [openColorPicker, setOpenColorPicker] = useState<string | null>(null);
  const colorPickerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setFormElements(initialElements);
  }, [initialElements]);

  useEffect(() => {
    onChange(formElements);
  }, [formElements, onChange]);

  const handleOutsideClick = useCallback((event: MouseEvent) => {
    const colorPickers = document.querySelectorAll('.chrome-picker');
    let clickedInsidePicker = false;
    
    colorPickers.forEach(picker => {
      if (picker.contains(event.target as Node)) {
        clickedInsidePicker = true;
      }
    });

    if (!clickedInsidePicker) {
      setOpenColorPicker(null);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [handleOutsideClick]);

  const addElement = (type: string) => {
    const newElement: FormElement = { 
      id: Date.now().toString(), 
      type, 
      label: `New ${type}`,
      options: type === 'checkbox' || type === 'radio' || type === 'dropdown' ? ['Option 1'] : [],
      color: '#ffffff'
    };
    setFormElements(prevElements => [...prevElements, newElement]);
  };

  const removeElement = (id: string) => {
    setFormElements(prevElements => prevElements.filter(element => element.id !== id));
  };

  const updateElement = (id: string, updates: Partial<FormElement>) => {
    setFormElements(prevElements => 
      prevElements.map(element => element.id === id ? { ...element, ...updates } : element)
    );
  };

  const addOption = (elementId: string) => {
    setFormElements(prevElements => 
      prevElements.map(element => 
        element.id === elementId 
          ? { ...element, options: [...element.options, `Option ${element.options.length + 1}`] }
          : element
      )
    );
  };

  const updateOption = (elementId: string, optionIndex: number, newValue: string) => {
    setFormElements(prevElements => 
      prevElements.map(element => 
        element.id === elementId 
          ? { 
              ...element, 
              options: element.options.map((option, index) => index === optionIndex ? newValue : option)
            }
          : element
      )
    );
  };

  const removeOption = (elementId: string, optionIndex: number) => {
    setFormElements(prevElements => 
      prevElements.map(element => 
        element.id === elementId 
          ? { ...element, options: element.options.filter((_, index) => index !== optionIndex) }
          : element
      )
    );
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const { source, destination } = result;

    if (result.type === 'element') {
      const reorderedElements = Array.from(formElements);
      const [reorderedItem] = reorderedElements.splice(source.index, 1);
      reorderedElements.splice(destination.index, 0, reorderedItem);
      setFormElements(reorderedElements);
    } else if (result.type === 'option') {
      const elementId = result.draggableId.split('-')[0];
      setFormElements(prevElements => 
        prevElements.map(element => {
          if (element.id === elementId) {
            const newOptions = Array.from(element.options);
            const [reorderedItem] = newOptions.splice(source.index, 1);
            newOptions.splice(destination.index, 0, reorderedItem);
            return { ...element, options: newOptions };
          }
          return element;
        })
      );
    }
  };

  const renderFormElement = (element: FormElement) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      updateElement(element.id, { label: e.target.value });
    };

    const handleColorChange = (color: { hex: string }) => {
      updateElement(element.id, { color: color.hex });
    };

    const handleColorInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      updateElement(element.id, { color: e.target.value });
    };

    const renderElementLabel = () => (
      <div className="flex items-center mb-2">
        <input
          type="text"
          value={element.label}
          onChange={handleChange}
          className="input input-bordered flex-grow mr-2"
          placeholder={`${element.type} label`}
        />
        <div className="relative" ref={colorPickerRef}>
          <Palette
            size={24}
            className="cursor-pointer text-gray-600 hover:text-blue-500"
            onClick={() => setOpenColorPicker(openColorPicker === element.id ? null : element.id)}
          />
          {openColorPicker === element.id && (
            <div className="absolute z-10 right-0 mt-2">
              <ChromePicker
                color={element.color}
                onChange={handleColorChange}
                className="chrome-picker"
              />
            </div>
          )}
        </div>
        <input
          type="text"
          value={element.color}
          onChange={handleColorInputChange}
          className="input input-bordered w-32 ml-2"
        />
      </div>
    );

    switch (element.type) {
      case 'short text':
        return (
          <div>
            {renderElementLabel()}
            <input type="text" placeholder="Short text" className="input input-bordered w-full" disabled />
          </div>
        );
      case 'long text':
        return (
          <div>
            {renderElementLabel()}
            <textarea placeholder="Long text" className="textarea textarea-bordered w-full" disabled />
          </div>
        );
      case 'number':
        return (
          <div>
            {renderElementLabel()}
            <input type="number" placeholder="Number" className="input input-bordered w-full" disabled />
          </div>
        );
      case 'checkbox':
      case 'radio':
        return (
          <div>
            {renderElementLabel()}
            {element.options.map((option, index) => (
              <div key={index} className="flex items-center mb-2">
                <input type={element.type} className="mr-2" disabled />
                <input 
                  type="text" 
                  value={option} 
                  onChange={(e) => updateOption(element.id, index, e.target.value)}
                  className="input input-bordered input-sm flex-grow"
                />
                <button onClick={() => removeOption(element.id, index)} className="btn btn-ghost btn-sm ml-2">
                  <X size={16} />
                </button>
              </div>
            ))}
            <button onClick={() => addOption(element.id)} className="btn btn-ghost btn-sm">
              <Plus size={16} className="mr-2" /> Add Option
            </button>
          </div>
        );
      case 'dropdown':
        return (
          <div>
            {renderElementLabel()}
            <select className="select select-bordered w-full mb-2" disabled>
              {element.options.map((option, index) => (
                <option key={index}>{option}</option>
              ))}
            </select>
            {element.options.map((option, index) => (
              <div key={index} className="flex items-center mb-2">
                <input 
                  type="text" 
                  value={option} 
                  onChange={(e) => updateOption(element.id, index, e.target.value)}
                  className="input input-bordered input-sm flex-grow"
                />
                <button onClick={() => removeOption(element.id, index)} className="btn btn-ghost btn-sm ml-2">
                  <X size={16} />
                </button>
              </div>
            ))}
            <button onClick={() => addOption(element.id)} className="btn btn-ghost btn-sm">
              <Plus size={16} className="mr-2" /> Add Option
            </button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="p-4 max-w-2xl mx-auto">
        <h1 className="text-2xl font-bold mb-4">Form Builder</h1>
        
        <Droppable droppableId="form-elements" type="element">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {formElements.map((element, index) => (
                <Draggable key={element.id} draggableId={element.id} index={index}>
                  {(provided) => (
                    <div 
                      ref={provided.innerRef} 
                      {...provided.draggableProps} 
                      className="mb-4 p-4 border rounded-lg relative"
                      style={{ 
                        backgroundColor: element.color,
                        ...provided.draggableProps.style
                      }}
                    >
                      <div {...provided.dragHandleProps} className="absolute top-2 left-2 cursor-move">
                        <GripVertical size={16} />
                      </div>
                      {renderFormElement(element)}
                      <button 
                        onClick={() => removeElement(element.id)}
                        className="btn btn-circle btn-sm absolute top-2 right-2"
                      >
                        <X size={16} />
                      </button>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        
        <div className="mt-6 grid grid-cols-2 md:grid-cols-3 gap-4">
          <button 
            className="btn btn-primary w-full"
            onClick={() => addElement('short text')}
          >
            <Plus size={16} className="mr-2" /> Short Text
          </button>
          <button 
            className="btn btn-primary w-full"
            onClick={() => addElement('long text')}
          >
            <Plus size={16} className="mr-2" /> Long Text
          </button>
          <button 
            className="btn btn-primary w-full"
            onClick={() => addElement('number')}
          >
            <Plus size={16} className="mr-2" /> Number
          </button>
          <button 
            className="btn btn-primary w-full"
            onClick={() => addElement('checkbox')}
          >
            <Plus size={16} className="mr-2" /> Checkbox
          </button>
          <button 
            className="btn btn-primary w-full"
            onClick={() => addElement('radio')}
          >
            <Plus size={16} className="mr-2" /> Radio
          </button>
          <button 
            className="btn btn-primary w-full"
            onClick={() => addElement('dropdown')}
          >
            <Plus size={16} className="mr-2" /> Dropdown
          </button>
        </div>
      </div>
    </DragDropContext>
  );
};

export default FormBuilder;